<template>
  <div class="p-0">
    <TTabs :tabs="tabs" @change-tab="changeTab" :activeTab="$route.query.tab">
      <template #detail>
        <Order orderStore="order_retail" orderItemStore="order_items" />
      </template>
      <template #lading_bill>
        <SCardLadingBillOfOrder :order="retail" />
      </template>
      <template #logs>
        <SCardLog :logableId="id" />
      </template>
      <template #transactions>
        <SCardTransaction :objectableId="id" />
      </template>
      <template #shipment_info>
        <SCardShipmentInfo :orderId="id" :userId="retail.customer_id" />
      </template>
      <template #owning_box>
        <SCardOwningBoxOfOrder
          :owningBoxes="retail.owning_boxes_from_origin"
          :orderItems="items"
        />
      </template>
      <template #contract>
        <SCardContract :orderId="id" />
      </template>
      <template #tracking>
        <Tracking :trackings="retail.related_trackings" />
      </template>
      <template #compensation>
        <SCardCompensationOrder :orderId="id" />
      </template>
      <template #credit_card>
        <SCardCreditOrder :order="retail" orderStore="order_retail" />
      </template>
      <template #tracking-badge>
        <CBadge color="primary">{{ retail.related_trackings.length }}</CBadge>
      </template>
      <template #lading_bill-badge>
        <CBadge color="primary">{{ ladingBills.length }}</CBadge>
      </template>
      <template #owning_box-badge>
        <CBadge color="primary">{{
          retail.owning_boxes_from_origin.length
        }}</CBadge>
      </template>
    </TTabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Order from "../components/Order.vue";
import Tracking from "../components/Tracking.vue";

export default {
  components: {
    Order,
    Tracking,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Order detail", icon: "cis-library-books" },
        { key: "shipment_info", name: "Shipment info", icon: "cis-map" },
        { key: "contract", name: "Contract", icon: "cis-document" },
        { key: "credit_card", name: "Credit card", icon: "cis-credit-card" },
        { key: "tracking", name: "Tracking", icon: "cis-queue" },
        { key: "lading_bill", name: "Lading bill", icon: "cis-truck" },
        { key: "owning_box", name: "SKU list", icon: "cis-box" },
        { key: "compensation", name: "Compensation", icon: "cis-library" },
        {
          key: "logs",
          name: "Logs",
          icon: "cis-note",
        },
        {
          key: "transactions",
          name: "Transactions arise",
          icon: "cis-speech-notes",
        },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store
        .dispatch("order.order_retail.detail.fetch", this.id)
        .catch(() => {
          this.$router.push("/sale/orders/retail");
        });
      this.$store.dispatch("order.order_items.push-query", {
        "filter[order_id]": this.id,
      });
    }
  },
  computed: {
    ...mapGetters({
      retail: "order.order_retail.detail",
      items: "order.order_items.list",
      ladingBills: "warehouse.lading_bills_of_orders.list",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
